<template>
  <v-container class="pa-0" fluid>
    <v-card class="pt-2">
      <div class="card-header-padding pt-10">
        <v-row>
          <v-col md="2" lg="2" cols="2">
            <v-select
              :items="allClassGroups"
              v-model="selectedClassGroup"
              return-object
              item-text="name"
              item-value="id"
              label="Бүлэг сонгох"
            >
            </v-select>
          </v-col>
          <v-col md="10" lg="10" cols="10">
            <v-row justify="end">
              <v-col>
                <v-select
                  :items="[1,2,3,4]"
                  v-model="currentSelectedSemester"
                  label="Улирал сонгоx"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  style="background-color: #ffe082"
                  :items="_getWeeks()"
                  v-model="currentSelectedWeek"
                  label="Долоо хоног сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col cols="8">
            <div>
              {{ _getWeekStatistics() }}
              <p class="text-sm text-body mb-0">
                <span class="red--text font-weight-bold">ТАЙЛБАР</span>: Т-2,
                Ө-1, Ч-0 : Тасалсан 2, Өвчтэй 1, Чөлөөтэй байхгүй гэж ойлгоно.
                <v-icon class="" size="20" color="red" @click.stop="_printStats"
                  >mdi-information-variant-circle-outline</v-icon
                >
              </p>
            </div>
          </v-col>
          <v-col v-if="selectedClassGroup" class="text-end mt-6">
            <h4>
              Бүлэг:
              <span class="blue--text">{{
                selectedClassGroup.STUDENT_GROUP_NAME
              }}</span>
              - {{ selectedClassGroup.TEACHER_NAME }}
            </h4>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="
          _getSelectedClassGroup() &&
          _getSelectedClassGroup()._classCalendarEelj_selected
        "
      >
        <div class="card-header-padding">
          <v-simple-table
            class="bborder"
            v-if="
              this._getSelectedClassGroup() &&
              this._getSelectedClassGroup()._classCalendarEeljs
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="
                      border-right: 1px solid #bbb;
                      border-left: 1px solid #bbb;
                    "
                  >
                    Нэр
                  </th>

                  <!-- <th style="width: 8%">Цаг</th> -->
                  <th
                    style="width: 200px"
                    v-for="(day, dindex) in days"
                    :key="'calendar-' + day.dayIndex + dindex"
                    class="text-center font-weight-bold"
                  >
                    {{ day.name }}
                    <span class="blue--text"> {{ _getDate(day) }}</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(xeelj, lindex) in xeeljs"
                  :key="xeelj + lindex + 'eelj'"
                >
                  <td
                    style="
                      border-right: 1px solid #bbb;
                      border-left: 1px solid #bbb;
                      width: 10% !important;
                    "
                  >
                    {{ xeelj }} -р цаг
                  </td>
                  <td
                    v-for="(day, dindex) in days"
                    :key="'cell-' + day.dayIndex + xeelj.index + dindex"
                    :title="''"
                    style="cursor: pointer"
                    class="px-1 mx-1 py-4"
                  >
                    <v-row>
                      <v-col cols="10">
                        <v-row>
                          <v-col
                            class="pr-0 pb-1 pt-0"
                            :cols="12 / _getCellLessons(day, xeelj).length"
                            v-for="(lesson, index) in _getCellLessons(
                              day,
                              xeelj
                            )"
                            :key="'cell' + day + xeelj + index"
                          >
                            <v-chip
                              @click.stop="_clickedCell(day, xeelj, index, 1)"
                              class="px-2"
                              style="
                                width: 100%;
                                height: 100%;
                                font-size: 10pt;
                                border-radius: 4px;
                                cursor: pointer;
                                display: block;
                              "
                              v-if="_getLesson2(day, xeelj, index)"
                              color="#ececec"
                            >
                              <p class="mb-0">
                                {{ _getLesson2(day, xeelj, index).name }},
                              </p>
                              <p
                                class="mb-0"
                                style="background-color: #ffe082"
                                v-if="_checkIfAttendancesExists(day, xeelj)"
                              >
                                {{ _getLessonAttendances(day, xeelj) }}
                              </p>
                              <p v-else class="red--text">Ирц бүртгээгүй</p>
                              <small style="display: block">
                                <span
                                  v-if="
                                    _getLesson2(day, xeelj, index).teachers !=
                                    null
                                  "
                                >
                                  {{ _getLesson2(day, xeelj, index).teachers }}
                                </span>
                                <span v-else>
                                  !!!!
                                  <v-icon>mdi-alert-decagram</v-icon></span
                                >
                              </small>
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!-- {{day.dayIndex}}-{{xeelj.index}} -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
      <div
        v-if="
          _getSelectedClassGroup() &&
          _getSelectedClassGroup()._classCalendarEelj2_selected
        "
      >
        <div
          class="card-header-padding"
          v-if="_getSelectedClassGroup()._classCalendarEelj2"
        >
          <v-row justify="space-between">
            <v-col>
              <h3>
                {{ _getSelectedClassGroup()._classCalendarEelj2.name2 }}
              </h3>
              <v-btn
                elevation="0"
                class="mb-0"
                color="#DCEDC8"
                @click="_editCalendar(2)"
              >
                {{ _getSelectedClassGroup()._classCalendarEelj2.name2 }}
              </v-btn>
            </v-col>
            <v-col class="text-end">
              <small
                class="pa-2"
                style="border-radius: 4px; background-color: red; color: white"
                >Нэмэлт xуваарь</small
              >
            </v-col>
          </v-row>
        </div>
        <div class="card-header-padding">
          <v-simple-table class="bborder" v-if="calendarData">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="
                      border-right: 1px solid #bbb;
                      border-left: 1px solid #bbb;
                    "
                  >
                    Нэр
                  </th>

                  <!-- <th style="width: 8%">Цаг</th> -->
                  <th
                    style="width: 200px"
                    v-for="(day, dindex) in days"
                    :key="'calendar-' + day.dayIndex + dindex"
                    class="text-center font-weight-bold"
                  >
                    {{ day.name }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(xeelj, lindex) in xeeljs"
                  :key="xeelj + lindex + 'eelj'"
                >
                  <td
                    style="
                      border-right: 1px solid #bbb;
                      border-left: 1px solid #bbb;
                      min-width: 120px !important;
                    "
                  >
                    {{ xeelj }} -р цаг
                  </td>
                  <td
                    v-for="(day, dindex) in days"
                    :key="'cell-' + day.dayIndex + xeelj.index + dindex"
                    :title="''"
                    style="cursor: pointer"
                    class="px-1 mx-1 py-4"
                  >
                    <v-row>
                      <v-col cols="10">
                        <v-row>
                          <v-col
                            class="pr-0 pb-1 pt-0"
                            :cols="
                              12 /
                              _getCellLessonsOfCalendarEelj2(day, xeelj).length
                            "
                            v-for="(
                              lesson, index
                            ) in _getCellLessonsOfCalendarEelj2(day, xeelj)"
                            :key="'cell' + day + xeelj + index"
                          >
                            <v-chip
                              @click.stop="_clickedCell(day, xeelj, index, 2)"
                              class="px-2"
                              style="
                                width: 100%;
                                height: 100%;
                                font-size: 10pt;
                                border-radius: 4px;
                                cursor: pointer;
                                display: block;
                              "
                              :style="
                                _getLessonOfCalendarEelj2(day, xeelj, index)
                                  .style
                              "
                              v-if="
                                _getLessonOfCalendarEelj2(day, xeelj, index)
                              "
                              color="#00BCD4"
                              dark
                            >
                              <p class="mb-0">
                                {{
                                  _getLessonOfCalendarEelj2(day, xeelj, index)
                                    .name
                                }}
                              </p>
                              <small style="display: block">
                                <span
                                  v-if="
                                    _getLessonOfCalendarEelj2(day, xeelj, index)
                                      .teachers != null
                                  "
                                >
                                  {{
                                    _getLessonOfCalendarEelj2(day, xeelj, index)
                                      .teachers
                                  }}
                                </span>
                                <span v-else>
                                  !!!!
                                  <v-icon>mdi-alert-decagram</v-icon></span
                                >
                              </small>
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!-- {{day.dayIndex}}-{{xeelj.index}} -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </v-card>
    <v-dialog
      v-model="selectLessonDialog"
      max-width="50%"
      v-if="selectedDay && selectedXeelj"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          @click="_print"
          class="pt-0 text-h5 text-typo justify-center font-weight-bold"
          ><span v-if="lessons && !selectedLesson"
            >Ээлжит xичээлээ сонгоно уу!</span
          >
          <span v-if="selectedLesson && selectedLesson.already"
            >{{ selectedLesson.courseInfo.COURSE_NAME }}
          </span>
        </v-card-title>
        <v-card-text class="text-h5 mt-4" v-if="selectedDay">
          <v-row>
            <v-col>
              <span class="red--text">{{ selectedDay.name }}</span> гараг,
              <span class="red--text"> {{ selectedXeelj }}</span
              >-р цаг
            </v-col>
            <v-col class="text-end" v-if="selectedCalendarEelj">
              <v-btn elevation="0" class="mb-0" color="#DCEDC8">
                {{ selectedCalendarEelj.name2 }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          class="text-h5"
          v-if="selectedLesson && selectedLesson.already"
        >
          <template v-if="selectedLesson.lessonGroup">
            <span> {{ selectedLesson.lessonGroup.name }}, </span>
            <span v-if="selectedLesson.lessonGroup.teacher">
              {{ selectedLesson.lessonGroup.teacher.teacherDisplayName }}</span
            >
            <v-card color="#FCE4EC" class="my-10 mx-10 text-center" v-else>
              <v-card-text class="red--text">
                Багш группт сонгогдоогүй байна!!! Миний ангийн xичээлүүд рүүгээ
                ороод багш нэмээрэй
              </v-card-text>
            </v-card>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showStatisticsDialog" max-width="50%" scrollable>
      <v-card class="py-4">
        <v-card-title class="text-typo text-h3">Тасалдаг сурагчид</v-card-title>
        <v-card-text>
          <v-data-table
            v-if="problematicStudents"
            style="overflow-y: auto"
            :items="problematicStudents"
            :headers="[
              {
                text: 'No',
                align: 'end',
                value: 'index',
                sortable: true,
                width: '1%',
                fixed: true,
              },
              {
                text: 'Нэр',
                align: 'start',
                sortable: true,
                value: 'firstName',
                fixed: true,
              },
              {
                text: 'Тасалсан',
                align: 'start',
                sortable: true,
                value: 'absents',
              },
            ]"
            hide-details
            hide-default-footer
            :items-per-page="-1"
          >
            <!-- <template slot="item,index" slot-scope="props">
            <tr>
              <td>{{ index }}</td>
              <td> {{ props.item.firstName }}</td>  
              <td>{{ props.item.absents }}</td>
            </tr>
        </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      allClassGroups: null,
      problematicStudents: null,
      showStatisticsDialog: false,
      currentWeekDays: null,
      currentSelectedWeek: null,
      currentSelectedSemester: 1,
      selectedCalendarIndex: 0,
      selectedCalendar: null,
      showEditCalendarDialog: false,
      school: null,
      selectedCalendarEelj: null,
      selectedEelj: null,
      selectedEelj2: null,

      showClassCalendarEelj2Dialog: false,
      calendarData: null,
      xeeljs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      calendarColors: [
        {
          subjectId: 1,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 2,
          fgColor: "#42A5F5",
          bgColor: "white",
        },
        {
          subjectId: 3,
          fgColor: "#90CAF9",
          bgColor: "black",
        },
        {
          subjectId: 4,
          fgColor: "#BBDEFB",
          bgColor: "black",
        },
        {
          subjectId: 5,
          fgColor: "#F48FB1",
          bgColor: "black",
        },
        {
          subjectId: 6,
          fgColor: "#C8E6C9",
          bgColor: "black",
        },
        {
          subjectId: 7,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 8,
          fgColor: "#81C784",
          bgColor: "black",
        },
        {
          subjectId: 9,
          fgColor: "#9575CD",
          bgColor: "white",
        },
        {
          subjectId: 10,
          fgColor: "#2962FF",
          bgColor: "white",
        },

        {
          subjectId: 11,
          fgColor: "#66BB6A",
          bgColor: "white",
        },

        {
          subjectId: 12,
          fgColor: "#FFF176",
          bgColor: "black",
        },
        {
          subjectId: 13,
          fgColor: "#FFD54F",
          bgColor: "black",
        },
        {
          subjectId: 14,
          fgColor: "#FFCA28",
          bgColor: "black",
        },
        {
          subjectId: 15,
          fgColor: "#FFAB91",
          bgColor: "black",
        },
        {
          subjectId: 16,
          fgColor: "#FF8A65",
          bgColor: "black",
        },
        {
          subjectId: 17,
          fgColor: "#FF7043",
          bgColor: "white",
        },
        {
          subjectId: 18,
          fgColor: "#B0BEC5",
          bgColor: "black",
        },
        {
          subjectId: 19,
          fgColor: "#F4511E",
          bgColor: "white",
        },
        {
          subjectId: 20,
          fgColor: "#E040FB",
          bgColor: "white",
        },
        {
          subjectId: 21,
          fgColor: "#40C4FF",
          bgColor: "black",
        },
        {
          subjectId: 22,
          fgColor: "#00E676",
          bgColor: "black",
        },
        {
          subjectId: 23,
          fgColor: "#DCE775",
          bgColor: "black",
        },
        {
          subjectId: 24,
          fgColor: "#FF5252",
          bgColor: "white",
        },
        {
          subjectId: 25,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 26,
          fgColor: "#B2DFDB",
          bgColor: "black",
        },
        {
          subjectId: 27,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
        {
          subjectId: 28,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },

        {
          subjectId: 195,
          fgColor: "#FFE082",
          bgColor: "black",
        },

        {
          subjectId: 196,
          fgColor: "#26C6DA",
          bgColor: "white",
        },
      ],
      selectedClassGroup: null,
      lessons: null,
      selectedLesson: null,
      selectLessonDialog: false,
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        // { name: "Бямба", dayIndex: 6 },
        // { name: "Ням", dayIndex: 7 },
      ],
      availableTimes: null,
      selectedXeelj: null,
      selectedDay: null,
      xeelj: null,
      attendances: null,
      dailyAttendances: null,
    };
  },

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._setupp();
  },
  watch: {
    selectedClassGroup() {
      this.updatee();
    },
    currentSelectedSemester() {
      this.updatee();
    },
    currentSelectedWeek() {
      this.updatee();
    },
  },
  methods: {
    updatee() {
      if (this.selectedClassGroup) {
        this.selectedClassGroup.ref
          .collection(
            "calendar-" +
              this.userData.school.currentYear +
              "-" +
              this.currentSelectedSemester
          )
          .orderBy("courseInfo.COURSE_NAME", "asc")
          .onSnapshot((docs) => {
            this.calendarData = [];
            docs.forEach(async (doc) => {
              let cal = doc.data();
              cal.ref = doc.ref;
              cal.id = doc.id;
              if (cal.lessonGroup) {
                cal.lessonGroup.ref.get().then((g) => {
                  if (g.exists == false) cal.ref.delete();
                  else {
                    let calG = g.data();
                    calG.id = g.id;
                    calG.ref = g.ref;
                    cal.lessonGroup = calG;
                    var xx = this.calendarData.find(
                      (item) => item.id == cal.id
                    );
                    if (!xx) this.calendarData.push(cal);
                  }
                });
              } else {
                cal.lessonGroup = null;
                var zzz = await cal.lessonRef.get();
                if (zzz.exists) {
                  cal.byTeachers = zzz.data().byTeachers
                    ? zzz.data().byTeachers
                    : null;
                } else cal.byTeachers = [];
                var xx = this.calendarData.find((item) => item.id == cal.id);
                if (!xx) this.calendarData.push(cal);
              }
            });
          });
        this.currentWeekDays = [];
        this.attendances = [];
        this.dailyAttendances = [];
        var ss = this.$store.state.calendarButez2.find(
          (item) => item.session == this.currentSelectedSemester
        );
        if (ss) {
          ss.months.forEach((mm) => {
            mm.days.forEach((dd) => {
              if (dd.weekNumber == this.currentSelectedWeek) {
                dd.month = mm.name;
                fb.db
                  .collectionGroup("executions")
                  .where("classGroupRef", "==", this.selectedClassGroup.ref)
                  .where("year", "==", this.userData.school.currentYear)
                  .where("month", "==", dd.month)
                  .where("day", "==", dd.day)
                  .get()
                  .then((docs) => {
                    var executions = [];
                    docs.forEach((doc) => {
                      let execution = doc.data();
                      execution.ref = doc.ref;
                      execution.id = doc.id;
                      executions.push(execution);
                    });
                    this.dailyAttendances.push({ executions: executions });
                  });
                this._getSelectedClassGroup()
                  .ref.collection(
                    "attendances-" + this.userData.school.currentYear
                  )
                  .where("year", "==", this.userData.school.currentYear)
                  .where("month", "==", dd.month)
                  .where("day", "==", dd.day)
                  .where("daily_attendance", "==", false)
                  .get()
                  .then((docs) => {
                    docs.forEach((doc) => {
                      let att = doc.data();
                      att.id = doc.id;
                      att.ref = doc.ref;
                      this.attendances.push(att);
                    });
                  });
                this.currentWeekDays.push(dd);
              }
            });
          });
        }
      }
    },
    _printStats() {
      var list = {};
      this.attendances.forEach((att) => {
        if (list[att.studentId]) {
          list[att.studentId].push(att);
        } else list[att.studentId] = [att];
      });

      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .orderBy("firstName", "asc")
        .where(
          "STUDENT_GROUP_ID",
          "==",
          this.selectedClassGroup["STUDENT_GROUP_ID"]
        )
        .onSnapshot((querySnapshot) => {
          this.problematicStudents = [];

          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            if (list[item.id]) {
              item.statuses = list[item.id];
              item.absents = list[item.id].filter(
                (att) => att.status == -4
              ).length;
              if (item.absents > 0) this.problematicStudents.push(item);
            } else item.statuses = [];
          });
          this.problematicStudents.sort((a, b) => {
            return b.absents - a.absents;
          });
          var counter = 0;
          for (var cc of this.problematicStudents) {
            cc.index = ++counter;
          }

          this.showStatisticsDialog = !this.showStatisticsDialog;
        });
    },
    _getWeekStatistics() {
      if (this.attendances && this.attendances.length > 0) {
        var absent = 0;
        var sick = 0;
        var excused = 0;
        this.attendances.forEach((att) => {
          if (att.status == -4) {
            absent++;
          } else if (att.status == -3) {
            sick++;
          } else if (att.status == -2) {
            excused++;
          }
        });
        return [absent, sick, excused];
      } else return [];
    },
    _getSelectedClassGroup() {
      return this.selectedClassGroup;
    },
    _checkIfAttendancesExists(day, xeelj) {
      if (this.dailyAttendances) {
        var weekDay = this.dailyAttendances[day.dayIndex - 1];
        if (weekDay && weekDay.executions) {
          var execution = weekDay.executions.find(
            (execution) => execution.xeelj == xeelj
          );
          if (execution) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    _getLessonAttendances(weekDay, xeelj) {
      var date = this._getDate(weekDay);
      if (date) {
        var month = date.split("/")[0];
        var day = date.split("/")[1];
        var matchingAttendances = this.attendances.filter(
          (att) => att.month == month && att.day == day && att.xeelj == xeelj
        );
        if (matchingAttendances && matchingAttendances.length > 0) {
          var absent = 0;
          var sick = 0;
          var excused = 0;
          matchingAttendances.forEach((att) => {
            if (att.status == -4) {
              absent++;
            } else if (att.status == -3) {
              sick++;
            } else if (att.status == -2) {
              excused++;
            }
          });
          return "Т-" + absent + ", Ө-" + sick + ", Ч-" + excused;
        } else {
          return "Бүгд ирсэн";
        }
      }
    },
    _getLessonAttendances2(weekDay, xeelj) {
      var date = this._getDate(weekDay);
      if (date) {
        var month = date.split("/")[0];
        var day = date.split("/")[1];
        var matchingAttendances = this.attendances.filter(
          (att) => att.month == month && att.day == day && att.xeelj == xeelj
        );

        if (matchingAttendances && matchingAttendances.length > 0) {
          var absent = 0;
          var sick = 0;
          var excused = 0;
          matchingAttendances.forEach((att) => {
            if (att.status == -4) {
              absent++;
            } else if (att.status == -3) {
              sick++;
            } else if (att.status == -2) {
              excused++;
            }
          });
          return [absent, sick, excused];
        } else {
          return null;
        }
      }
    },
    _getDate(day) {
      var cc = this.currentWeekDays
        ? this.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;
      if (cc) return cc.month + "/" + cc.day;
      else return null;
    },
    // _getCurrentDays(csw) {
    //   var currentWeekDays = [];
    //   var ss = this.$store.state.calendarButez2.find(
    //     (item) => item.session == this.currentSelectedSemester
    //   );
    //   if (ss) {
    //     ss.months.forEach((mm) => {
    //       var day = mm.days.find((dd) => dd.weekNumber == csw);
    //       currentWeekDays.push(day);
    //     });
    //   }
    //   return currentWeekDays;
    // },
    _getWeeks() {
      var xx = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (xx) return xx.weeks;
      else return null;
    },

    _editCalendar(calendarIndex) {
      if (calendarIndex == 1) {
        this.selectedCalendar =
          this._getSelectedClassGroup()._classCalendarEeljs;
        this.selectedCalendarIndex = 1;
      } else if (calendarIndex == 2) {
        this.selectedCalendar =
          this._getSelectedClassGroup()._classCalendarEelj2;
        this.selectedCalendarIndex = 2;
      }
      this.showEditCalendarDialog = !this.showEditCalendarDialog;
    },
    _getFilteredXeelj() {
      var list = [];
      if (
        this._getSelectedClassGroup() &&
        this._getSelectedClassGroup()._classCalendarEeljs &&
        this.xeelj
      ) {
        for (const eelj of this.xeelj) {
          if (
            eelj.ref.path !=
            this._getSelectedClassGroup()._classCalendarEeljs.ref.path
          ) {
            list.push(eelj);
          }
        }
      }
      return list;
    },
    _backPop() {
      this.$router.back();
      this.userData.school.ref.collection("_feedback_calendars").doc().set({
        school: this.userData.school,
        problem: "eelj baih bna.",
      });
    },
    _print() {
      console.log(this._getSelectedClassGroup().ref.path);
      if (this.selectedLesson) console.log(this.selectedLesson.ref.path);
    },
    _getEelj() {
      var names = null;
      if (this._getSelectedClassGroup()._classCalendarEeljs) {
        // this._getSelectedClassGroup()._classCalendarEeljs.forEach((eelj) => {
        //   names = names == null ? eelj.name2 : names + ", " + eelj.name2;
        // });
        names = this._getSelectedClassGroup()._classCalendarEeljs.name2;
      }
      return names;
    },
    _confirmClassEelj2() {
      console.log(this.selectedEelj2.ref.path);
      this._getSelectedClassGroup()
        .ref.update({
          _classCalendarEelj2_selected: true,
          _classCalendarEelj2: this.selectedEelj2,
        })
        .then(() => {
          this._getSelectedClassGroup()._classCalendarEelj2 =
            this.selectedEelj2;
          this._getSelectedClassGroup()._classCalendarEelj2_selected = true;
          this.showClassCalendarEelj2Dialog =
            !this.showClassCalendarEelj2Dialog;
        });
    },
    _getCellColor(lesson) {
      var x = this.calendarColors.find(
        (item) => item.subjectId == lesson.courseInfo.SUBJECT_AREA_ID
      );
      if (x) return x;
      else {
        return {
          subjectId: -1,
          fgColor: "#bbb",
          bgColor: "black",
        };
      }
    },
    _getTeacherNames(item) {
      var names = null;
      if (item.byTeachers) {
        item.byTeachers.filter(
            (tt) => !tt.xSemester || (tt.xSemester && tt.xSemester == this.currentSelectedSemester)
          ).forEach((teacher) => {
          names =
            names == null
              ? teacher.teacherDisplayName
              : names + ", " + teacher.teacherDisplayName;
        });
      }

      return names;
    },
    _getMyClassInfo() {
      if (this.zSelectedTeacher)
        return this.zSelectedTeacher[
          "myClassInfo-" + this.userData.school.currentYear
        ];
      else
        return this.userData["myClassInfo-" + this.userData.school.currentYear];
    },
    _calendarConfirm() {
      this._getSelectedClassGroup().calendarConfirmed =
        !this._getSelectedClassGroup().calendarConfirmed;
      this._getSelectedClassGroup().ref.update({
        calendarConfirmed: this._getSelectedClassGroup().calendarConfirmed,
      });
    },

    async _clickedCell(day, xeelj, index, calendarEeljIndex) {
      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      if (calendarEeljIndex == 1) {
        this.selectedLesson = this._getCellLessons(day, xeelj)[index];
      } else {
        this.selectedLesson = this._getCellLessonsOfCalendarEelj2(day, xeelj)[
          index
        ];
      }
      this.selectedLesson.already = true;
      this.selectLessonDialog = true;
    },

    _getLesson(day, xeelj) {
      var kk = null;
      if (this._getSelectedClassGroup() && this.calendarData) {
        for (var ca of this.calendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
            kk = ca;
            var cc = this._getCellColor(ca);
            kk.style =
              "background-color:" + cc.fgColor + "; color:" + cc.bgColor + ";";
            kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);
            kk.teachers = this._getTeacherNames(kk);

            break;
          }
        }
      }
      return kk;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },
    _getLesson2(day, xeelj, index) {
      var ll = this._getCellLessons(day, xeelj);
      return ll[index];
    },

    _getCellLessons(day, xeelj) {
      var lessons = [];
      if (this._getSelectedClassGroup() && this.calendarData) {
        for (var ca of this.calendarData) {
          if (
            ca.calendarEelj &&
            ca.calendarEelj.eeljRef.path ==
              this._getSelectedClassGroup()._classCalendarEeljs.ref.path
          ) {
            if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
              var kk = ca;
              var cc = this._getCellColor(ca);
              kk.style =
                "background-color:" +
                cc.fgColor +
                "; color:" +
                cc.bgColor +
                ";";
              kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);
              if (kk.lessonGroup) {
                kk.name = "[" + kk.lessonGroup.groupIndex + "] " + kk.name;
                kk.teachers = kk.lessonGroup.teacher
                  ? kk.lessonGroup.teacher.teacherDisplayName
                  : null;
              } else kk.teachers = this._getTeacherNames(kk);
              lessons.push(kk);
            }
          }

          // }//

          // }
        }
      }
      return lessons;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },
    _getLessonOfCalendarEelj2(day, xeelj, index) {
      var ll = this._getCellLessonsOfCalendarEelj2(day, xeelj);
      return ll[index];
    },
    _getCellLessonsOfCalendarEelj2(day, xeelj) {
      var lessons = [];
      if (this._getSelectedClassGroup() && this.calendarData) {
        for (var ca of this.calendarData) {
          if (
            ca.calendarEelj &&
            ca.calendarEelj.eeljRef.path ==
              this._getSelectedClassGroup()._classCalendarEelj2.ref.path
          ) {
            if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
              var kk = ca;
              var cc = this._getCellColor(ca);
              kk.style =
                "background-color:" +
                cc.fgColor +
                "; color:" +
                cc.bgColor +
                ";";
              kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);
              if (kk.lessonGroup) {
                kk.name = "[" + kk.lessonGroup.groupIndex + "] " + kk.name;
                kk.teachers = kk.lessonGroup.teacher
                  ? kk.lessonGroup.teacher.teacherDisplayName
                  : null;
              } else kk.teachers = this._getTeacherNames(kk);
              lessons.push(kk);
            }
          }
        }
      }
      return lessons;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },
    _isContained(dep, lesson) {
      if (lesson && lesson.departments) {
        for (const dep2 of lesson.departments) {
          if (dep.id == dep2.id) {
            return true;
          }
        }
      }
      return false;
    },

    async _setupp() {
      this.loading = true;
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .where("startYear", "==", this.userData.school.currentYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          this.allClassGroups = [];
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            this.loading = true;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  // console.log(prog)
                  if (!this.selectedClassGroup) this.selectedClassGroup = prog;
                  dep.classGroups.push(prog);
                  this.allClassGroups.push(prog);
                });
                this.loading = false;
              });
            this.departments.push(dep);
          });
          this.loading = false;
        });

      this.userData.school.ref
        .collection("xeelj")
        .get()
        .then((docs) => {
          this.xeelj = [];
          docs.docs.forEach((doc) => {
            let eelj = doc.data();
            eelj.id = doc.id;
            eelj.ref = doc.ref;
            // console.log(eelj.classLevelID);
            // if (parseInt(this._getSelectedClassGroup().ACADEMIC_LEVEL) > 5) {
            //   if (eelj.classLevelID == 2) {
            //     eelj.name2 = eelj.name + ", " + eelj.xeelj + "-р ээлж";
            //     this.xeelj.push(eelj);
            //   }
            // } else {
            //   if (eelj.classLevelID == 1) {
            //     eelj.name2 = eelj.name + ", " + eelj.xeelj + "-р ээлж";
            //     // console.log(eelj.id);
            //     this.xeelj.push(eelj);
            //   }
            // }
          });
        });
      // this.userData.school.ref
      //   .collection("lessons-" + this.userData.school.currentYear)
      //   .where("programId", "==", this._getSelectedClassGroup().id)
      //   .orderBy("courseInfo.COURSE_NAME", "asc")
      //   .onSnapshot((docs) => {
      //     this.lessons = [];
      //     docs.forEach((doc) => {
      //       let lesson = doc.data();
      //       lesson.ref = doc.ref;
      //       lesson.id = doc.id;
      //       lesson.name2 = lesson.courseInfo.COURSE_NAME
      //         ? this.lessons.length +
      //         1 +
      //         ". " +
      //         lesson.courseInfo.COURSE_NAME
      //         : "";

      //       if (
      //         lesson.esisLessonType &&
      //         lesson.esisLessonType.esisLessonTypeId > 1
      //       ) {
      //         lesson.lessonGroups = null;
      //         lesson.ref
      //           .collection("lesson-groups")
      //           .get()
      //           .then((docs) => {
      //             lesson.lessonGroups = [];
      //             docs.docs.forEach((doc) => {
      //               let gg = doc.data();
      //               gg.id = doc.id;
      //               gg.ref = doc.ref;
      //               lesson.lessonGroups.push(gg);
      //             });
      //           });
      //       }
      //       this.lessons.push(lesson);
      //     });
      //   });
      this.currentSelectedSemester = 4;
      this.currentSelectedWeek = this._getCurrentSchoolWeekNumber();
    },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      // var year = date.getFullYear()
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day) {
                cc = dd.weekNumber;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

.v-chip .v-chip__content {
  align-items: start !important;
  display: block;
  height: 100%;
  max-width: 100%;
}

.addIcon:hover {
  font-size: 34px;
  transition: font-size 0.2s;
  /* Add a smooth transition for a nicer effect */
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
